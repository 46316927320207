import React, {useState} from 'react';
import Form from "@rjsf/core";
import './App.css';

const savedRecords = JSON.parse(window.localStorage.getItem('saved')) || [];

const previousSituations = savedRecords.map((record) => record && record.situation);
const previousThoughts = savedRecords.flatMap((record) => record && record.thoughts && Array.isArray(record.thoughts) && record.thoughts.map(thought => thought.thought));
const previousEmotions = savedRecords.flatMap((record) => record && record.emotions && record.emotions.map(emotion => emotion.emotion));
const previousDistortions = savedRecords.flatMap((record) => record && record.distortion);
// const previousAlternatives = savedRecords.map((record) => record && record.alternatives);
const previousOutcomes = savedRecords.map((record) => record && record.outcome);


console.log(previousThoughts);

const schema = {
  type: "object",
  required: ["situation", "thoughts", "emotion", "distortion", "alternatives", "outcome"],
  properties: {
    date: {
      type: "string",
    },
    situation: {
      title: "Tilanne",
      description: "Mikä tapahtuma tai ajatus johti epämiellyttävään tunteeseen? Miltä kehossa tuntui?",
      type: "string",
      examples: previousSituations,
    },
    thoughts: {
      title: "Automaattiset ajatukset",
      description: "Mitä ajatuksia sinulla oli ja kuinka paljon uskoit niihin?",
      type: "array",
      default: [''],
      minLength: 1,
      items: {
        type: 'object',
        required: ['thought', 'strength'],
        properties: {
          thought: {
            title: 'Ajatus',
            type: 'string',
            examples: previousThoughts,
          },
          strength: {
            title: 'Voimakkuus',
            type: 'integer',
            minimum: 0,
            maximum: 100,
            default: 50
          }
        }
      },
    },
    emotion: {
      title: "Tunteet",
      description: "Mitä tunteita tunsit? Kuinka voimakkaita tunteet olivat?",
      type: "array",
      default: [''],
      minLength: 1,
      items: {
        type: 'object',
        required: ['emotion', 'strength'],
        properties: {
          emotion: {
            title: 'Tunne',
            type: 'string',
            examples: [ 
              'innostuneisuus',
              'huojennus',
              'rentous',
              'onnellisuus',
              'huolestuneisuus',
              'hellyys',
              'ihastus',
              'himo',
              'kiintymys',
              'luottamus',
              'ylpeys',
              'jännitys',
              'ällistys',
              'helpotus',
              'ilahtuminen',
              'kiitollisuus',
              'ujous',
              'nolous',
              'häpeä',
              'ahdistus',
              'lamaannus',
              'ulkopuolisuus',
              'pettymys',
              'inho',
              'apeus',
              'kaiho',
              'ikävä',
              'syyllisyys',
              'turhautuminen',
              'nöyryytys',
              'ärtymys',
              'kateus',
              'katkeruus',
              'raivo',
              ...previousEmotions
            ]
          },
          strength: {
            title: 'Voimakkuus',
            type: 'integer',
            minimum: 0,
            maximum: 10,
            default: 5
          }
        }
      },
    },
    interpretation: {
      title: "Miten muuten tilannetta voisi tulkita?",
      type: "string",
      default: '',
      minLength: 1,
    },
    distortion: {
      title: "Ajatuksen vääristymät",
      description: "Huomasitko tehneesi ajatusvirheitä? Jos huomasit niin minkälaisia?",
      type: "array", 
      default: [''],
      minLength: 1,
      items: {
        type: 'string',
        examples: [
          'Suodattaminen: huomio kiinnittyy pieniin vastoinkäymisiin kokonaisuutta huomioimatta',
          'Mustavalkoinen ajattelu: asiat nähdään ääripäiden kautta ilman keskivaihetta',
          'Yliyleistäminen: tehdään liiallisia yleistyksiä epämääräisen tiedon perusteella',
          'Ajatusten lukeminen: käsitys että tietää mitä toiset ajattelevat ja tuntevat',
          'Katastrofiajattelu: oletetaan, että pahin mahdollinen vaihtoehto tapahtuu',
          'Suurentelu: vaikeuksien näkeminen todellista suurempina',
          'Henkilökohtaistaminen, itsen leimaaminen: oletetaan että muiden reaktiot kohdistuvat aina itseen',
          'Pitäisi-ajattelu: keinotekoisten sääntöjen kautta oman ja muiden käyttäytymisen arviointia',
          'Itsensä syyttäminen: uskomus että ikävät tapahtumat ovat aina omaa syytä',
          'Positiivinen poissulkeminen: hyvät asiat tapahtuvat onnen tai muun ansiosta, kielteiset',
          'Mielivaltaiset johtopäätökset: pienien vihjeiden perusteella perusteettomien johtopäätösten tekeminen',
          'Mitä enemmän kärsii, sitä suurempi palkinto myöhemmin',
          ...previousDistortions,
        ]
      }
    },
    alternatives: {
      title: "Automaattiset ajatukset",
      description: "Kuinka paljon uskot automaattisiin ajatuksiin nyt?",
      type: "array",
      default: [''],
      minLength: 1,
      items: {
        type: 'object',
        required: ['thought', 'strength'],
        properties: {
          thought: {
            title: 'Ajatus',
            type: 'string',
            readOnly: true,
          },
          strength: {
            title: 'Voimakkuus',
            type: 'integer',
            minimum: 0,
            maximum: 100,
            default: 50
          }
        }
      },
    },
    // alternatives: {
    //   title: "Lopputulos",
    //   description: "Kuinka paljon uskot automaattisiin ajatuksiin nyt? Kuinka vahvoja tunteet ovat?",
    //   type: "string", 
    //   examples: previousAlternatives,
    // },
    outcome: {
      title: "Lopputulos",
      description: "Mitä aiot tehdä seuraavaksi? Mitä teit jo?",
      type: "string", 
      examples: previousOutcomes,
    },
  },
};
 
const uiSchema = {
  situation: {
    "ui:widget": "textarea",
    "ui:autofocus": true,
  },
  thoughts: {
    "ui:options": {
      "orderable": false,
    },
    items: {
      thought: {
        "ui:options": {
          label: false
        },
        "ui:placeholder": "..."
      },
      strength: {
        "ui:options": {
          label: false
        },
        "ui:widget": 'range'
      }
    }
  },
  emotion: {
    "ui:options": {
      "orderable": false,
    },
    items: {
      emotion: {
        "ui:options": {
          label: false
        },
        "ui:placeholder": "..."
      },
      strength: {
        "ui:options": {
          label: false
        },
        "ui:widget": 'range'
      }
    }
  },
  interpretation: {
    "ui:widget": "textarea"
  },
  alternatives: {
    "ui:options": {
      "orderable": false,
    },
    items: {
      thought: {
        "ui:options": {
          label: false,
          addable: false,
          removable: false
        },
        "ui:placeholder": "..."
      },
      strength: {
        "ui:options": {
          label: false,
          addable: false,
          removable: false
        },
        "ui:widget": 'range'
      }
    }
  },
  outcome: {
    "ui:widget": "textarea"
  },
  date: {"ui:widget": "hidden"}
};

function App() {
  const [currentId, setCurrentId] = useState();
  const [records, setRecords] = useState(savedRecords);
  const [currentData, setCurrentData] = useState({});
  // const [savedData, setSavedData] = useState({});

  const handleSubmit = ({formData}) => {
    const newRecord = {
      ...formData,
      date: new Date().toISOString(),
    }
    const newRecords = [newRecord, ...records.filter(r => r.date !== currentId)];
    window.localStorage.setItem('saved', JSON.stringify(newRecords));
    setRecords(newRecords);
    setCurrentId('');
    setCurrentData({});
  }

  const handleSelectRecord = (recordId) => {
    setCurrentId(recordId);
    setCurrentData(records.find(r => r.date === recordId)||{});
  }

  const handleOnChange = ({formData}) => {
    setCurrentData(formData);
  }
  
  const handleOnBlur = (event) => {
    if (event.includes('root_thoughts')) {
      setCurrentData({
        ...currentData, 
        alternatives: currentData.thoughts
      })
    }
  }

  return (
    <div className="App">
      <h2>Ajatusloki</h2>
      <Form 
        schema={schema}
        uiSchema={uiSchema}
        onSubmit={handleSubmit}
        formData={currentData}
        onBlur={handleOnBlur}
        onChange={handleOnChange}
      >
        <button type="submit" className="btn btn-success">Tallenna</button>
      </Form>
      {currentId && 
        <button
          className="btn btn-info" 
          onClick={() => handleSelectRecord()}
        >
          Uusi merkintä
        </button>
      }
      <ul>
      {records.map((record) => (
        <button 
          className="record"
          style={{background: record.date === currentId ? '#ddd' : '#7dbaff'}}
          key={record.date}
          onClick={() => handleSelectRecord(record.date)}
        >
          Ajatus: {new Date(record.date).toLocaleString('fi')}
        </button>
      ))}
      </ul>
    </div>
  );
}

export default App;
